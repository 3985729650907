<template>
    <zk-admin-table title="新入职/人员调岗" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <!-- <el-button type="default" size="small" @click="getData('xlsx')" icon="el-icon-download">导出EXCEL</el-button> -->
                <el-button type="primary" size="small" @click="handleAdd" v-if="permission.ruzhitiaogang_shenqing"
                    icon="el-icon-plus">新入职申请
                </el-button>
                <el-button type="default" size="small" @click="handleTrans" v-if="permission.ruzhitiaogang_shenqing"
                    icon="el-icon-plus">调岗申请
                </el-button>
                <el-button type="default" size="small" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </el-row>
        </template>

        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData">
                <el-form-item label="状态" prop="status" clearable>
                    <el-select v-model="search.status" placeholder="审批状态" clearable style="width:120px">
                        <el-option label="已申请" value="0"></el-option>
                        <el-option label="已审批" value="1"></el-option>
                        <el-option label="审批未通过" value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="type" clearable>
                    <el-select v-model="search.type" placeholder="类型" clearable style="width:120px">
                        <el-option label="新入职" value="new"></el-option>
                        <el-option label="调岗" value="trans"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="三级单位" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.name" clearable style="width:120px"></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.idnum" clearable></el-input>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="50" align="center" />
        <el-table-column prop="status" label="状态" width="120" align="center">

            <template slot-scope="scope">
                <div>
                    <el-tag size="small" type="primary" v-if="scope.row.status === 0">已申请</el-tag>
                    <el-tag size="small" type="success" v-else-if="scope.row.status === 1">已审批</el-tag>
                    <el-tooltip v-else-if="scope.row.status === -1" :content="scope.row.text_shenpi" placement="top">
                        <el-tag size="small" type="danger">审批不通过</el-tag>
                    </el-tooltip>
                    <el-tag size="small" type="defalult" v-else>{{ scope.row.status }}</el-tag>
                </div>
                <div v-if="scope.row.trans_oid">调岗</div>
                <div v-else>新入职</div>
            </template>
        </el-table-column>
        <el-table-column label="单位" width="">

            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }}</small>
                <div>
                    <small>{{ scope.row.FJDW }}</small>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="人员信息" width="180">
            <template slot-scope="scope">
                <div>{{ scope.row.XM }} <small>{{ scope.row.XB }} {{ scope.row.NL }}岁</small></div>
                <small> {{ isFullIdCard[scope.$index] ? scope.row.SFZH : maskIdCardRegex(scope.row.SFZH) }}</small>
                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                    @click="toggleIdCard(scope.$index)"></i>
            </template>
        </el-table-column>
        <el-table-column label="职工信息" width="130">

            <template slot-scope="scope">
                <div>{{ scope.row.ZGLX }}</div>
                <small>档案年份: {{ scope.row.DANF }}</small>
            </template>
        </el-table-column>
        <el-table-column prop="user_shenqing" label="申请信息" width="220">

            <template slot-scope="scope">
                <div>申请人：{{ scope.row.user_shenqing }}</div>
                <small>申请时间: {{ scope.row.time_shenqing }}</small>
            </template>
        </el-table-column>
        <el-table-column prop="user_shenqing" label="审批信息" width="220">

            <template slot-scope="scope">
                <div>审批人：{{ scope.row.user_shenpi }}</div>
                <small>审批时间: {{ scope.row.time_shenpi }}</small>
            </template>
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">

            <template slot-scope="scope">
                <el-button size="mini" @click="handleUpdate(scope.row)">
                    查看详情
                </el-button>
            </template>
        </el-table-column>

        <template v-slot:dialog>
            <el-dialog title="填写表单" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false"
                :fullscreen="false">
                <el-tabs v-model="activeName" style="height: 55vh;overflow: auto;">
                    <el-tab-pane label="基本信息" name="tab1">
                        <el-form ref="form1" :model="form" label-width="100px">
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="职工类型" prop="ZGLX"
                                        :rules="{ required: true, message: '不能为空', trigger: 'change' }">
                                        <el-select v-model="form.ZGLX" placeholder="请选择" :disabled="form._readonly">
                                            <el-option label="普通职工" value="普通职工"></el-option>
                                            <el-option label="资深技师" value="资深技师"></el-option>
                                            <el-option label="二级工程师" value="二级工程师"></el-option>
                                            <el-option label="一级工程师" value="一级工程师"></el-option>
                                            <el-option label="企业高级专家" value="企业高级专家"></el-option>
                                            <el-option label="企业首席专家" value="企业首席专家"></el-option>
                                            <el-option label="油田公司技能专家" value="油田公司技能专家"></el-option>
                                            <el-option label="集团公司技能专家" value="集团公司技能专家"></el-option>
                                            <el-option label="集团公司技能大师" value="集团公司技能大师"></el-option>
                                            <el-option label="处级干部" value="处级干部"></el-option>
                                            <el-option label="局级干部" value="局级干部"></el-option>
                                            <el-option label="博士研究生(激励)" value="博士研究生(激励)"></el-option>
                                            <el-option label="硕士研究生(激励)" value="硕士研究生(激励)"></el-option>
                                            <el-option label="本科生(激励)" value="本科生(激励)"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="工种" prop="ZYGG"
                                        :rules="{ validator: checkGongling, trigger: 'blur' }">
                                        <el-input v-model.trim="form.ZYGG" clearable
                                            :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="工龄" prop="GL"
                                        :rules="[{ validator: checkGongling, trigger: 'blur' }]">
                                        <el-input v-model.trim="form.GL" clearable
                                            :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="姓名" prop="XM"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.XM" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="身份证号" prop="SFZH" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },
    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号格式不正确', trigger: 'blur' }]">
                                        <el-input v-model.trim="form.SFZH" :disabled="form._readonly"
                                            @change="readAge"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="性别" prop="XB"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.XB" :disabled="form._readonly">
                                            <el-radio label="男">男</el-radio>
                                            <el-radio label="女">女</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <template v-if="form.trans_data && form.trans_data.id">
                                    <el-col :span="8">
                                        <el-form-item label="原二级单位" prop="EJDWID">
                                            <select-subsidiary-2 v-model="form.trans_data.EJDWID" :disabled="true" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="原三级单位" prop="SJDWID">
                                            <select-subsidiary-3 :ejdw_id="form.trans_data.EJDWID"
                                                v-model="form.trans_data.SJDWID" :disabled="true" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="原四级单位" prop="SJDWID">
                                            <select-subsidiary-4 :sjdw_id="form.trans_data.SJDWID"
                                                v-model="form.trans_data.FJDWID" :disabled="true" />
                                        </el-form-item>
                                    </el-col>
                                </template>
                                <el-col :span="8">
                                    <el-form-item label="二级单位" prop="EJDWID"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <select-subsidiary-2 v-model="form.EJDWID" :disabled="form._readonly" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="三级单位" prop="SJDWID"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <select-subsidiary-3 :ejdw_id="form.EJDWID" v-model="form.SJDWID"
                                            :disabled="form._readonly" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="四级单位" prop="SJDWID"
                                        :rules="{ required: false, message: '不能为空', trigger: 'blur' }">
                                        <select-subsidiary-4 :sjdw_id="form.SJDWID" v-model="form.FJDWID"
                                            :disabled="form._readonly" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="16">
                                    <el-form-item label="女工/家属" prop="TJLX_NGJS">
                                        <el-radio-group v-model="form.TJLX_NGJS" :disabled="form._readonly">
                                            <el-radio label="">无</el-radio>
                                            <el-radio label="女工">女工</el-radio>
                                            <el-radio label="家属">家属</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="档案年份" prop="DANF" v-show="$store.getters.permission.staff_DANF"
                                        :rules="{ validator: validateDAanf, trigger: 'blur' }">
                                        <el-input v-model.trim="form.DANF" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form ref="form6" :model="form" label-width="100px">
                                        <el-form-item label="备注">
                                            <el-input type="textarea" rows="2" v-model.trim="form.remarks"
                                                :disabled="form._readonly"></el-input>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="职业信息" name="tab2">
                        <el-form ref="form2" :model="form" label-width="120px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="噪声" prop="SFZS"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFZS" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="ZSJHGL" v-if="form.SFZS == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.ZSJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="高温" prop="SFGW"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFGW" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="GWJHGL" v-if="form.SFGW == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.GWJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="毒物" prop="SFDW"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFDW" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="DWJHGL" v-if="form.SFDW == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.DWJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                    <el-form-item label="接害因素" prop="JHYS" v-if="form.SFDW == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-checkbox-group v-model="form.JHYS" :disabled="form._readonly">
                                            <el-checkbox label="硫化氢" name="JHYS"></el-checkbox>
                                            <el-checkbox label="溶剂汽油" name="JHYS"></el-checkbox>
                                            <el-checkbox label="氨" name="JHYS"></el-checkbox>
                                            <el-checkbox label="苯" name="JHYS"></el-checkbox>
                                            <el-checkbox label="氯气" name="JHYS"></el-checkbox>
                                            <el-checkbox label="锰及化合物" name="JHYS"></el-checkbox>
                                            <el-checkbox label="甲醇" name="JHYS"></el-checkbox>
                                            <el-checkbox label="盐酸" name="JHYS"></el-checkbox>
                                            <el-checkbox label="一氧化碳" name="JHYS"></el-checkbox>
                                            <el-checkbox label="硫酸" name="JHYS"></el-checkbox>
                                            <el-checkbox label="氢氧化钠" name="JHYS"></el-checkbox>
                                            <el-checkbox label="氮氧化钠" name="JHYS"></el-checkbox>
                                            <el-checkbox label="二氧化硫" name="JHYS"></el-checkbox>
                                            <el-checkbox label="戊烷" name="JHYS"></el-checkbox>
                                            <el-checkbox label="正己烷" name="JHYS"></el-checkbox>
                                            <el-checkbox label="四氯化碳" name="JHYS"></el-checkbox>
                                            <el-checkbox label="酸雾酸酐" name="JHYS"></el-checkbox>
                                            <el-checkbox label="致喘物" name="JHYS"></el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="驾驶员" prop="SFJSY"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFJSY" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="JSYJHGL" v-if="form.SFJSY == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.JSYJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                    <el-form-item label="类型" prop="JSYJHLX" v-if="form.SFJSY == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-select v-model="form.JSYJHLX" placeholder="请选择" :disabled="form._readonly">
                                            <el-option label="大型车驾驶员" value="大型车驾驶员"></el-option>
                                            <el-option label="小型车驾驶员" value="小型车驾驶员"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="粉尘" prop="SFFC"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFFC" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="FCJHGL" v-if="form.SFFC == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.FCJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                    <el-form-item label="接害类型" prop="FCJHLX" v-if="form.SFFC == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-select v-model="form.FCJHLX" placeholder="请选择" :disabled="form._readonly">
                                            <el-option label="煤尘" value="煤尘"></el-option>
                                            <el-option label="矽尘" value="矽尘"></el-option>
                                            <el-option label="棉尘" value="棉尘"></el-option>
                                            <el-option label="其他粉尘" value="其他粉尘"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="高处作业" prop="SFGC"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFGC" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="GCJHGL" v-if="form.SFGC == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.GCJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="放射" prop="SFFS"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFFS" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="FSJHGL" v-if="form.SFFS == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.FSJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                    <el-form-item label="放射类型" prop="FSJHLX" v-if="form.SFFS == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-select v-model="form.FSJHLX" placeholder="请选择" :disabled="form._readonly">
                                            <el-option label="工业辐照3A" value="3A"></el-option>
                                            <el-option label="工业探伤3B" value="3B"></el-option>
                                            <el-option label="发光涂料工业3C" value="3C"></el-option>
                                            <el-option label="放射性同位素生产3D" value="3D"></el-option>
                                            <el-option label="测井3E" value="3E"></el-option>
                                            <el-option label="加速器运行3F" value="3F"></el-option>
                                            <el-option label="其它3G" value="3G"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="放射剂量" prop="FSJHJL" v-if="form.SFFS == '是'"
                                        placeholder="上一年度接触放射剂量"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.FSJHJL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="压力容器作业" prop="SFYL"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFYL" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="YLJHGL" v-if="form.SFYL == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.YLJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="电工" prop="SFDG"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-radio-group v-model="form.SFDG" :disabled="form._readonly">
                                            <el-radio label="是">是</el-radio>
                                            <el-radio label="否">否</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="接害工龄" prop="DGJHGL" v-if="form.SFDG == '是'"
                                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                        <el-input v-model.trim="form.DGJHGL" :disabled="form._readonly"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="申请说明" name="tab3">
                        <el-form ref="form3" :model="form" label-width="100px">
                            <el-form-item label="申请原因" prop="SQYY"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input type="textarea" v-model.trim="form.SQYY"
                                    :disabled="form._readonly"></el-input>
                            </el-form-item>
                            <el-form-item label="附件" prop="FJ"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <btnUploadImg v-model="form.FJ" :disabled="form._readonly" />
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
                <span slot="footer" class="dialog-footer">
                    <div v-show="form._readonly">
                        <template v-if="form.status == 0">
                            <el-button style="float:left" type="primary" v-if="permission.ruzhitiaogang_shenpi"
                                @click="handleShenpi(1)" :loading="loading">审批通过</el-button>
                            <el-button style="float:left" type="warning" v-if="permission.ruzhitiaogang_shenpi"
                                @click="handleShenpi(-1)" :loading="loading">审批不通过</el-button>
                        </template>
                        <el-button style="float:left" @click="handleReset" type="default" icon="el-icon-delete">重
                            置</el-button>
                    </div>
                    <el-button v-if="form.id && !form._readonly" style="float:left" @click="handleDel" type="danger"
                        icon="el-icon-delete">删 除</el-button>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" v-if="!form._readonly" @click="handleSubmit" :loading="loading">确
                        定</el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: "company-ruzhitiaogang",
    path: "/main/company/ruzhitiaogang",
    data() {
        return {
            loading: false,
            dialogVisible: false,
            activeName: 'tab1',
            tableData: [],
            form: { zhiye: [], JHYS: [], trans_data: {} },
            permission: [],
            search: {},
            table: {
                columns: [],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            },
            isFullIdCard: {}
        };
    },
    methods: {
        readAge() {
            //获取年龄
            if (this.form.SFZH.length != 18) return
            const currentYear = new Date().getFullYear();
            const birthYear = parseInt(this.form.SFZH.substr(6, 4), 10);
            this.form.NL = currentYear - birthYear // + 1;
            this.form.XB = (this.form.SFZH.slice(14, 17) % 2 ? "1" : "2" == '1') ? '男' :'女'
            this.form.DANF = birthYear
        },
        validateDAanf(rule, value, callback) {
            if (value && value !== '') {
                value = parseInt(value)
                if (this.form.SFZH && this.form.SFZH.length > 17) {
                    var UUserCard = this.form.SFZH;
                    var birthDateStr = UUserCard.substring(6, 10);
                    var starAge = parseInt(birthDateStr) - 3;
                    var endAge = parseInt(birthDateStr) + 3;
                    if (starAge <= value && value <= endAge) {
                        callback();
                    } else {
                        callback(new Error(`档案年份不能大于${endAge},不能小于${starAge}`));
                    }
                } else {
                    callback(new Error('请先输入身份证号'));
                }
            } else {
                callback();
            }
        },
        checkGongling(rule, value, callback) {
            const fieldsToCheck = ['SFZS', 'SFGW', 'SFDW', 'SFJSY', 'SFFC', 'SFGC', 'SFFS', 'SFYL', 'SFDG'];
            if (!value && fieldsToCheck.some(field => this.form[field] === '是')) {
                return callback(new Error('有职业病体检时不能为空'));
            }
            callback();
        },
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index) {
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        async handleFormSelect(e) {
            this.form.XM = e.XM
            this.form.staff_id = e.id
        },
        handleAdd() {
            this.form = {
                XM: '',
                SFZS: '否',
                SFGW: '否',
                SFDW: '否',
                SFJSY: '否',
                SFFC: '否',
                SFGC: '否',
                SFFS: '否',
                SFYL: '否',
                SFDG: '否',
                zhiye: [],
                JHYS: [],
                _readonly: false,
                XB: "",
                NL: ""
            }
            if (this.$refs['form1']) this.$refs['form1'].resetFields()
            if (this.$refs['form2']) this.$refs['form2'].resetFields()
            if (this.$refs['form3']) this.$refs['form3'].resetFields()
            this.dialogVisible = true
        },
        handleUpdate(row) {
            this.form = JSON.parse(JSON.stringify(row))
            this.form.JHYS = this.form.JHYS || []
            // 申请中和未通过审核的可以修改
            if ([0, -1].includes(this.form.status) && this.form.user_shenqing == this.$store.getters.username) {
                this.form._readonly = false
            } else {
                this.form._readonly = true
            }
            if (this.$refs['form1']) this.$refs['form1'].resetFields()
            if (this.$refs['form2']) this.$refs['form2'].resetFields()
            if (this.$refs['form3']) this.$refs['form3'].resetFields()
            this.dialogVisible = true
        },
        async handleTrans() {
            let SFZH = await this.$prompt('请输入身份证号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                return value
            }).catch(() => {
                return ''
            });
            if (!SFZH) return
            const resp = await this.$http.get(`/company/newstaff/trans/${SFZH}`)
            if (resp.data.length == 0) {
                this.$alert('未找到记录', '出错了', { type: 'error' })
                return
            }
            if (resp.data.length > 1) {
                this.$alert('该员工数据存在重复记录，请清理后再试', '出错了', { type: 'error' })
                return
            }
            let data = resp.data[0]
            data.trans_oid = data.id
            data.trans_data = JSON.stringify(data)
            data.FJ = []
            data.JHYS = data.JHYS || []
            data._readonly = false
            delete data.id
            delete data.EJDWID
            delete data.SJDWID
            delete data.FJDWID
            this.form = data
            this.dialogVisible = true
        },
        async handleReset() {
            this.$confirm('确认要重置状态重新审批吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    const resp = await this.$http.post(`/company/newstaff/reset`, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        await this.getData()
                        this.dialogVisible = false
                    }
                } catch (err) {
                    this.$message.error(err.message)
                }
            }).catch(() => {
            });
        },
        async handleDel() {
            this.$confirm('确认删除吗,该操作不可恢复？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    const resp = await this.$http.delete(`/company/newstaff/${this.form.id}`)
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        await this.getData()
                        this.dialogVisible = false
                    }
                } catch (err) {
                    this.$message.error(err.message)
                }
            }).catch(() => {
            });
        },
        async handleShenpi(status) {
            let reason = ''
            if (status == -1) {
                reason = await this.$prompt('请输入原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    return value
                }).catch(() => {
                    return ''
                });
                if (!reason) return
            }
            let data = JSON.parse(JSON.stringify(this.form))
            data.status = status
            data.text_shenpi = reason
            try {
                const resp = await this.$http.post(`/company/newstaff/shenpi`, data)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') {
                    await this.getData()
                    this.dialogVisible = false
                }
            } catch (err) {
                this.$message.error(err.message)
            }
        },
        async handleSubmit() {
            const valid = await this.$refs['form1'].validate().catch(() => false)
            if (!valid) {
                this.activeName = 'tab1'
                return
            }
            const valid2 = await this.$refs['form2'].validate().catch(() => false)
            if (!valid2) {
                this.activeName = 'tab2'
                return
            }
            const valid3 = await this.$refs['form3'].validate().catch(() => false)
            if (!valid3) {
                this.activeName = 'tab3'
                return
            }
            if (!this.form.trans_oid && !this.form.FJ) {
                this.$message.error('新入职申请必须上传附件')
                this.activeName = 'tab3'
                return
            }
            this.loading = true
            try {
                const resp = await this.$http.post('/company/newstaff', this.form)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') {
                    await this.getData()
                    this.dialogVisible = false
                }
            } catch (err) {
                this.$message.error(err.message)
            }
            this.loading = false
        },
        async getData(xlsx) {
            this.loading = true
            try {
                if (xlsx == 'xlsx') {
                    this.search.export = xlsx
                    const resp = await this.$http.get(`/company/newstaff?page=${this.table.page}&pageSize=${this.table.pageSize}`, {
                        params: this.search,
                        responseType: 'blob'
                    })
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                } else {
                    this.search.export = ''
                    const resp = await this.$http.get(`/company/newstaff?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData();
    },
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}

::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label,
::v-deep .el-radio__input.is-disabled+span.el-radio__label {
    background-color: #fff;
    color: #666;
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after,
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
    border-color: #fff;
}

::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #fff;
}

::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #409EFF;
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #409EFF;
    border-color: #409EFF;
}

::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    border: 1px solid #e4e7ed;
    color: #666;
}


.header {
    margin-bottom: 20px;
}
</style>
