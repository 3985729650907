<template>
    <div class="page">
        <div class="header">
            <el-page-header @back="$router.go(-1)" content="系统开关" />
        </div>
        <div class="main-content" style="margin-top:18px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-card shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>二级单位</span>
                        </div>
                        <el-table :data="tableData.filter(e => e.role_id == 3)" border stripe size="mini"
                            v-loading="loading" width="100%" height="650px"
                            :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                            <el-table-column type="index" label="序号" align="center" width="50" />
                            <el-table-column prop="name" label="名称" align="center" width="" />
                            <el-table-column prop="name" label="名称" align="center" width="">
                                <template slot-scope="scope">
                                    <el-switch :value="scope.row.switch" @change="handleSwitch(scope.row)"
                                        active-color="#13ce66" inactive-color="#ff4949" />
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card shadow="hover">
                        <div slot="header" class="clearfix">
                            <span>三级单位</span>
                        </div>
                        <el-table :data="tableData.filter(e => e.role_id == 4)" border stripe size="mini"
                            v-loading="loading" width="100%" height="650px"
                            :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                            <el-table-column type="index" label="序号" align="center" width="50" />
                            <el-table-column prop="name" label="名称" align="center" width="" />
                            <el-table-column prop="name" label="名称" align="center" width="">
                                <template slot-scope="scope">
                                    <el-switch :value="scope.row.switch" @change="handleSwitch(scope.row)"
                                        active-color="#13ce66" inactive-color="#ff4949" />
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'settings-switch',
    path: '/main/settings/switch',
    data() {
        return {
            loading: false,
            tableData: [],
        }
    },
    methods: {
        async handleSwitch(row) {
            this.loading = true
            try {
                const resp = await this.$http.post('/settings/switch/switch', {
                    id: row.id,
                    switch: !row.switch
                })
                if (resp.data.status != 'success') {
                    this.$alert(resp.data.msg, '出错了', { type: 'error' })
                } else {
                    row.switch = !row.switch
                }
                this.getData()
            } catch (error) {

            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get('/settings/switch')
                this.tableData = resp.data.data
            } catch (error) {

            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    line-height: 40px;

    .btns {
        float: right;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}

.el-tag {
    margin: 2px;
}
</style>