<template>
    <el-table ref="planTable" :data="tableData" style="width: 100%" height="100%" size="mini">
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="50" />
        <el-table-column prop="alias" label="单位" align="left" width="150">
            <template slot-scope="scope">
                {{ scope.row.alias || scope.row.name }}
            </template>
        </el-table-column>
        <el-table-column prop="alias" label="已检" align="center" width="70">
            <template slot-scope="scope">
                <router-link class="link" :to="`/main/task/checkup?TJLX=${target}&dwid=${scope.row.id}`">{{
                    scope.row[doneField] || 0
                }}</router-link>
            </template>
        </el-table-column>
        <el-table-column label="总人数" align="center" width="80">
            <template slot-scope="scope">
                <router-link class="link" :to="`/main/company/staff?TJLX=${target}&dwid=${scope.row.id}`">{{
                    scope.row[totalField] || 0
                }}</router-link>
            </template>
        </el-table-column>
        <el-table-column :label="item" align="center" v-for="(item, index) in tableLabel" :key="index">
            <el-table-column prop="item" align="center" label="计划" width="60">
                <template slot-scope="scope">
                    <div style="cursor: pointer">
                        <router-link class="link"
                            :to="`/main/company/staff?TJLX=${target}&dwid=${scope.row.id}&label=${item}&status=0`">{{
                                scope.row[item + '_count'] || 0 }}</router-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="item" align="center" label="剩余" width="60">
                <template slot-scope="scope">
                    <router-link class="link"
                        :to="`/main/task/surplus?target=${target}&dwid=${scope.row.id}&label=${item}`">
                        {{ scope.row[item + '_count'] - scope.row[item + '_done'] || 0 }}
                    </router-link>
                </template>
            </el-table-column>
        </el-table-column>
        <!-- <el-table-column prop="dongjie" label="冻结" align="center" width="80" /> -->
        <!-- <el-table-column prop="chaojian" label="异常" align="center" width="80" /> -->
    </el-table>
</template>

<script>
export default {
    props: ['tableLabel', 'tableData', 'table', 'target', 'totalField', 'doneField'],
    data() {
        return {
            // tableData: []
        }
    },
    watch: {
        'tableData': {
            // eslint-disable-next-line no-unused-vars
            handler(newValue, oldValue) {
                // console.log('tableData', newValue, oldValue)
                this.adjustColumnWidth()
            },
            deep: true,
        }
    },
    methods: {
        adjustColumnWidth() {
            const table = this.$refs.planTable;
            console.log('refs', this.$refs.planTable)
            const columns = table.columns;
            let column = columns[1]
            // columns.forEach((column) => {
            const headerWidth = this.getTextWidth(column.label);
            let maxContentWidth = 0;
            this.tableData.forEach((row) => {
                const content = row[column.property];
                if (content) {
                    const contentWidth = this.getTextWidth(content);
                    maxContentWidth = Math.max(maxContentWidth, contentWidth);
                }
            });
            let width = Math.max(100, headerWidth, maxContentWidth) + 20; // 加上一些额外的间距
            column.width = width;
            // });
            table.doLayout();
        },
        getTextWidth(text) {
            // const canvas = document.createElement('canvas');
            // const context = canvas.getContext('2d');
            // // context.font = '14px Arial'; // 根据实际字体样式调整
            // const metrics = context.measureText(text);
            // return metrics.width;
            const averageCharWidth = 20;
            return text.length * averageCharWidth;
        }
    }
}
</script>

<style scoped>
.link {
    text-decoration: none;
    color: #333;
}
</style>
